import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Carousel, { Modal, ModalGateway } from "react-images";
import Gallery from "./Gallary";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      overflowX: "hidden",
      overflowY: "auto",
      padding: "0 60px",
      display: "flex",
      flexDirection: "column",
    },
    title: {
      margin: "20px 0px 10px",
      fontSize: "20px",
    },
    subTitle: {
      fontSize: "15px",
      color: "#F1F1F1",
      marginBottom: "10px !important",
    },
  })
);

const SearchResults = (props) => {
  const classes = useStyles({});
  const { images = [] } = props;
  const [currentImage] = useState(0);
  const [viewerIsOpen] = useState(false);
  const photos = images.map((img) => {
    return {
      brief: img[0][1],
      distance: img[1],
      data: JSON.parse(img[0][2]||'{}'),
    };
  });


  return (
    <div className={classes.root}>
      
      <div className={classes.title}>
        <h3 className={classes.title}>搜索结果</h3>
      </div>
      <Gallery images={photos} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal>
            <Carousel
              currentIndex={currentImage}
              views={photos.map((x) => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </div>
  );
};

export default SearchResults;
